import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Col, Collapse, Form, Row, TabPane } from "reactstrap"
import classnames from "classnames"
import { GetAllQCTests } from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"

const QCTypeTab = ({ nextTab, previousTab, request, setRequest }) => {
  const [entries, setEntries] = useState([])
  const setLoading = useLoading()
  const [activeCategory, setActiveCategory] = useState(null)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setLoading(true)
    const result = await GetAllQCTests()
    setEntries(result)
    setLoading(false)
  }

  const toggleCategory = category => {
    setActiveCategory(activeCategory === category ? null : category)
  }

  const categories = [...new Set(entries.map(entry => entry.category))]

  return (
    <TabPane tabId={1}>
      <Row>
        <Col lg="12">
          <h5 className="font-size-14 mb-4">Request Type</h5>
          <div className="accordion accordion-flush" id="accordionFlushExample">
            {categories.map(category => (
              <div className="accordion-item" key={category}>
                <h2 className="accordion-header" id={`heading${category}`}>
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: activeCategory !== category,
                    })}
                    type="button"
                    onClick={() => toggleCategory(category)}
                    style={{ cursor: "pointer" }}
                  >
                    {category}
                  </button>
                </h2>
                <Collapse
                  isOpen={activeCategory === category}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    {entries
                      .filter(entry => entry.category === category)
                      .map(entry => (
                        <div className="form-check mb-3" key={entry.id}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="qcTest"
                            id={"qc-test" + entry.id}
                            value={entry.id}
                            onChange={() =>
                              setRequest({ ...request, qcTestID: entry.id })
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"qc-test" + entry.id}
                          >
                            {entry.name}
                          </label>
                        </div>
                      ))}
                  </div>
                </Collapse>
              </div>
            ))}
          </div>
        </Col>
      </Row>
      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn btn-primary disabled me-3"
          onClick={previousTab}
        >
          Previous
        </button>
        <button
          className="btn btn-primary"
          type="button"
          disabled={!request?.qcTestID}
          onClick={nextTab}
        >
          Next
        </button>
      </div>
    </TabPane>
  )
}

export default QCTypeTab
