import React, { useState } from "react"
import { Card, CardBody } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"

const Overview = ({ entry, printBtn }) => {
  return (
    <Card className="">
      <CardBody className="pb-0">
        <div className="table-responsive mt-4">
          <table className="table">
            <tbody>
              <tr>
                <th scope="col">Request Number</th>
                <td scope="col">{entry?.id}</td>
              </tr>
              <tr>
                <th scope="col">Category</th>
                <td scope="col">{entry?.qcTestCategory}</td>
              </tr>
              <tr>
                <th scope="col">Type</th>
                <td scope="col">{entry?.qcTestName}</td>
              </tr>
              <tr>
                <th scope="col">Part Number</th>
                <td scope="col">{entry?.partNumber}</td>
              </tr>
              <tr>
                <th scope="row">Manufacturer:</th>
                <td>{entry?.manufacturer}</td>
              </tr>
              <tr>
                <th scope="row">Stock</th>
                <td>{entry?.quantity} pc.</td>
              </tr>
              <tr>
                <th scope="row">Price</th>
                <td>
                  {entry?.price
                    ? entry.price.toFixed(5) + " " + entry.currency
                    : "-"}{" "}
                </td>
              </tr>
              <tr>
                <th scope="col">Requestor</th>
                <td scope="col">
                  {entry?.requestor?.name}{" "}
                  {/* <i
                    className="bx bx-info-circle ms-2 font-size-18"
                    style={{ cursor: "pointer" }}
                    onClick={_ => setCompanyInfoModal(true)}
                  /> */}
                </td>
              </tr>
              <tr>
                <th scope="col">Seller</th>
                <td scope="col">
                  {entry?.seller?.name ?? "-"}{" "}
                  {/* <i
                    className="bx bx-info-circle ms-2 font-size-18"
                    style={{ cursor: "pointer" }}
                    onClick={_ => setCompanyInfoModal(true)}
                  /> */}
                </td>
              </tr>
              <tr>
                <th scope="col">Labor</th>
                <td scope="col">
                  {entry?.qcLabor?.name ?? "-"}{" "}
                  {/* <i
                    className="bx bx-info-circle ms-2 font-size-18"
                    style={{ cursor: "pointer" }}
                    onClick={_ => setCompanyInfoModal(true)}
                  /> */}
                </td>
              </tr>
              <tr>
                <th scope="col">IPN</th>
                <td scope="col">{entry?.internalPart}</td>
              </tr>
              <tr>
                <th scope="col">Internal Order Number</th>
                <td scope="col">{entry?.internalOrderNumber}</td>
              </tr>
              <tr>
                <th scope="col">Location</th>
                <td scope="col">
                  {entry?.seller
                    ? entry?.seller?.postalCode + " " + entry?.seller?.city
                    : entry?.requestor?.postalCode +
                      " " +
                      entry?.requestor?.city}{" "}
                  <br />
                  {entry?.seller
                    ? entry?.seller?.country + ", " + entry?.seller?.region
                    : entry?.requestor?.country +
                      " " +
                      entry?.requestor?.region}
                </td>
              </tr>

              <tr>
                <th scope="row">Notes:</th>
                <td>{entry?.notes ? entry.notes : "-"}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-end mb-3">
          {printBtn && (
            <Link
              className="btn btn-primary"
              to={"/qc-print/" + entry?.id}
              target="_blank"
              rel="noreferrer"
            >
              Print Request
            </Link>
          )}
        </div>
      </CardBody>
    </Card>
  )
}

export default Overview
