import moment from "moment"
import React from "react"
import { Card, CardBody } from "reactstrap"
import SimpleBar from "simplebar-react"
import QCOfferListItem from "./ActivityListItems/QCOffer"
import QCAcceptOfferListItem from "./ActivityListItems/QCAcceptOffer"
import QCTrackingNumberInput from "./ActivityListItems/QCTrackingNumberInput"
import QCRequestCreated from "./ActivityListItems/QCRequestCreated"
import QCInReviewListItem from "./ActivityListItems/QCInReview"
import QCReportListItem from "./ActivityListItems/QCReport"
import QCAcceptReportListItem from "./ActivityListItems/QCAcceptReport"
import QCOrderInformationInput from "./ActivityListItems/QCOrderInformationInput"
import QCDeclinedInfo from "./ActivityListItems/QCDeclinedInfo"

const Activity = ({ entry, myUser, reload }) => {
  return (
    <Card>
      <CardBody>
        <div className="d-flex align-items-start">
          <div className="me-2">
            <h5 className="card-title mb-4">Activity</h5>
          </div>
        </div>
        <SimpleBar className="mt-2">
          <ul className="verti-timeline list-unstyled">
            <QCRequestCreated entry={entry} statusIndex={1} />
            <QCOfferListItem
              entry={entry}
              myUser={myUser}
              statusIndex={2}
              reload={reload}
            />
            <QCAcceptOfferListItem
              entry={entry}
              myUser={myUser}
              statusIndex={3}
              reload={reload}
            />

            <QCOrderInformationInput
              entry={entry}
              myUser={myUser}
              statusIndex={4}
              reload={reload}
            />

            <QCTrackingNumberInput
              entry={entry}
              myUser={myUser}
              statusIndex={5}
              reload={reload}
              trackingCount={1}
            />

            {/* <QCInReviewListItem
              entry={entry}
              myUser={myUser}
              statusIndex={6}
              reload={reload}
            /> */}

            <QCReportListItem
              entry={entry}
              myUser={myUser}
              statusIndex={6}
              reload={reload}
            />

            <QCAcceptReportListItem
              entry={entry}
              myUser={myUser}
              statusIndex={7}
              reload={reload}
            />

            {!entry?.dateDeclined && (
              <QCTrackingNumberInput
                entry={entry}
                myUser={myUser}
                statusIndex={8}
                reload={reload}
                trackingCount={2}
              />
            )}

            <QCDeclinedInfo
              entry={entry}
              myUser={myUser}
              statusIndex={7}
              reload={reload}
              trackingCount={2}
            />
          </ul>
        </SimpleBar>
      </CardBody>
    </Card>
  )
}

export default Activity
