import React, { useState } from "react"
import { useLoading } from "helpers/custom_hooks"
import { UpdateQCRequest } from "helpers/backend_helper"
import { displayMessage } from "helpers/misc_helpers"
import { Col, Row } from "reactstrap"
import moment from "moment"

const QCTrackingNumberInput = ({
  entry,
  myUser,
  statusIndex,
  reload,
  trackingCount,
}) => {
  const setLoading = useLoading()
  const [trackingNumber, setTrackingNumber] = useState("")
  const [shippingService, setShippingService] = useState("")

  const handleSave = async () => {
    setLoading(true)
    try {
      if (trackingCount == 1) {
        entry.trackingNumber1 = trackingNumber
        entry.shippingService1 = shippingService
        entry.dateShipping1 = new Date()
      } else {
        entry.trackingNumber2 = trackingNumber
        entry.shippingService2 = shippingService
        entry.dateShipping2 = new Date()
      }
      entry.statusIndex++

      await UpdateQCRequest(entry)
      reload()
    } catch (error) {
      error?.response?.status == 400
        ? displayMessage(error.response.data, setError)
        : displayMessage("An unexpected error happened!", setError)
      console.log(error)
    }
  }

  const isUserSeller = () => myUser?.company?.id == entry?.sellerCompanyID
  const IsUserQCLabor = () => myUser?.company?.id == entry?.qcLaborID

  console.log(
    entry?.statusIndex,
    statusIndex,
    entry?.statusIndex >= statusIndex
  )
  return (
    <>
      {entry?.statusIndex >= statusIndex ? (
        <li
          className={
            "event-list " + (entry?.statusIndex == statusIndex ? "active" : "")
          }
        >
          <div
            className={
              "event-timeline-dot " +
              (entry?.statusIndex == statusIndex ? "bx-fade-right" : "")
            }
          >
            <i className="bx bx-right-arrow-circle font-size-18"></i>
          </div>
          <div className="d-flex">
            <div className="flex-shrink-0 me-3">
              <h5 className="font-size-14">
                {moment(entry.dateShipping1).format("DD.MM.YYYY")}{" "}
                <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
              </h5>
            </div>
            <div className="flex-grow-1">
              <div>In Transit</div>
            </div>
          </div>
          <div className="mt-2">
            <p className="mb-0" style={{ cursor: "pointer" }}>
              <b>Trackingnumber:</b>{" "}
              {(trackingCount == 1
                ? entry?.trackingNumber1
                : entry?.trackingNumber2) ?? "-"}{" "}
              <i className="bx bx-copy text-primary ms-1" />
            </p>
            <p className="mt-1">
              <b>Delivery Service:</b>{" "}
              {(trackingCount == 1
                ? entry?.shippingService1
                : entry?.shippingService2) ?? "-"}
            </p>
          </div>
        </li>
      ) : (
        entry?.statusIndex == statusIndex - 1 &&
        ((trackingCount == 1 && isUserSeller()) ||
          (trackingCount == 2 && IsUserQCLabor())) && (
          <Row>
            <Col
              className="px-5"
              style={{ maxWidth: "400px", minWidth: "150px" }}
            >
              <p>
                {trackingCount == 1
                  ? "Kindly dispatch the specified quantity to the address mentioned in the QC Offer and indicate that this case has been shipped:"
                  : "Kindly dispatch the specified quantity to the requestor address and indicate that this case has been shipped:"}
              </p>
              <label>Shipping Service:</label>
              <input
                className="form-control mb-3"
                value={shippingService}
                onChange={e => setShippingService(e.target.value)}
              />
              <label>Tracking Number:</label>
              <input
                className="form-control mb-3"
                value={trackingNumber}
                onChange={e => setTrackingNumber(e.target.value)}
              />
              <button
                type="button"
                onClick={handleSave}
                className="btn btn-primary"
              >
                Mark as shipped
              </button>
            </Col>
          </Row>
        )
      )}
    </>
  )
}

export default QCTrackingNumberInput
