import {
  CreateQCComment,
  UploadChatAttachment,
  UploadQCChatAttachment,
} from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import { displayMessage } from "helpers/misc_helpers"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  UncontrolledTooltip,
} from "reactstrap"
import SimpleBar from "simplebar-react"

const QCComments = ({ entry, reload }) => {
  const setLoading = useLoading()
  const [newMessage, setNewMessage] = useState("")
  const [file, setFile] = useState()

  const sendMessage = async () => {
    setLoading(true)
    try {
      let result
      if (file) {
        const formData = new FormData()
        formData.append("file", file)
        formData.append("id", entry.id)
        result = await UploadQCChatAttachment(formData)
      }

      const reqObj = {
        qcRequestID: entry?.id,
        text: newMessage,
        fileURL: result?.url,
      }

      console.log(reqObj)

      await CreateQCComment(reqObj)
      reload()
      setNewMessage("")
      setFile()
    } catch (error) {
      console.log(error)
    }
  }

  const simpleBarRef = useRef()

  const handleFileChange = event => {
    event.preventDefault()
    setFile(event.target.files[0])
    event.target.value = ""
  }

  useEffect(() => {
    // This function scrolls the content to the bottom
    const scrollToBottom = () => {
      const scrollElement = simpleBarRef.current.getScrollElement()
      scrollElement.scrollTop = scrollElement.scrollHeight
    }

    // Call scrollToBottom whenever you need, e.g., after adding a comment
    scrollToBottom()
  }, []) // You might want to add dependencies here, like the comments list

  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardBody>
            <SimpleBar ref={simpleBarRef} style={{ maxHeight: "300px" }}>
              <div>
                <ul className="list-group list-group-flush">
                  {entry?.comments?.map((comment, index) => (
                    <li className="list-group-item py-3" key={index}>
                      <div className="d-flex">
                        <div className="avatar-xs me-3">
                          <div className="avatar-title rounded-circle bg-light text-primary">
                            <i className="bx bxs-user"></i>
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="font-size-14 mb-1">
                            {comment.username} {comment.type}
                            <small className="text-muted float-end">
                              {moment(comment.timestamp).format(
                                "DD.MM.YY HH:mm"
                              )}
                            </small>
                          </h5>
                          <p className="text-muted">{comment.text}</p>
                          {comment.fileURL && (
                            <Row className="">
                              <Col className="col-auto px-0">
                                <i className="bx bxs-file ms-1 font-size-18" />
                              </Col>
                              <Col className="col-auto ">
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={comment.fileURL}
                                  className="text-decoration-underline"
                                >
                                  {new URL(comment.fileURL).pathname
                                    .split("/")
                                    .pop()}
                                </a>
                              </Col>
                            </Row>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </SimpleBar>
            {file && (
              <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete mx-3">
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <i className="bx bxs-file ms-4 font-size-20" />
                    </Col>
                    <Col>
                      <a className="text-muted font-weight-bold">{file.name}</a>
                      <p className="mb-0">
                        <strong>{file.formattedSize}</strong>
                      </p>
                    </Col>
                    <Col className="text-end">
                      <button
                        type="button"
                        id="close_toggle"
                        className="btn btn-sm btn-link ms-3 fs-18 text-danger"
                        onClick={() => setFile()}
                      >
                        <i className="bx bx-x align-middle font-size-24"></i>
                      </button>
                    </Col>
                  </Row>
                </div>
              </Card>
            )}
            <Row className="mt-3 mx-0">
              <Col>
                <div className="position-relative">
                  <input
                    type="text"
                    value={newMessage ?? ""}
                    onChange={e => setNewMessage(e.target.value)}
                    className="form-control chat-input"
                    placeholder="Enter Comment..."
                  />
                  <div className="chat-input-links">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item">
                        <label
                          htmlFor="imageInput"
                          style={{
                            color: "#556ee6",
                            fontSize: 20,
                            marginTop: "8px",
                            cursor: "pointer",
                          }}
                        >
                          <i
                            className="mdi mdi-file-document-outline"
                            id="Filetooltip"
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="Filetooltip"
                          >
                            Add File
                          </UncontrolledTooltip>
                        </label>
                        <input
                          type="file"
                          id="imageInput"
                          className="d-none"
                          onChange={handleFileChange}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col className="col-auto d-flex align-items-end">
                <Button
                  type="button"
                  color="primary"
                  onClick={sendMessage}
                  className="btn btn-rounded chat-send w-md"
                >
                  <span className="d-none d-sm-inline-block me-2">Send</span>
                  <i className="mdi mdi-send" />
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default QCComments
