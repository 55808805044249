import { Link } from "react-router-dom"
import { formatDate } from "helpers/misc_helpers"
import React from "react"
import { Badge } from "reactstrap"
import moment from "moment"

export const columns = [
  {
    dataField: "id",
    text: "Request Number",
    sort: true,
  },
  {
    dataField: "qcTestName",
    text: "Test",
    sort: true,
  },
  {
    dataField: "partNumber",
    text: "Part no.",
    sort: true,
  },
  {
    dataField: "manufacturer",
    text: "Manufacturer",
    sort: true,
  },
  {
    dataField: "quantity",
    text: "Quantity",
    sort: true,
    formatter: cell => <>{cell} pc.</>,
    headerStyle: _ => ({
      textAlign: "center",
    }),
    align: () => "center",
  },
  {
    dataField: "statusIndex",
    text: "Status",
    sort: true,
    formatter: (cell, row) => (
      <QCStatus status={cell} declined={row.dateDeclined} />
    ),
    headerStyle: _ => ({
      textAlign: "center",
    }),
    align: () => "center",
  },
  {
    dataField: "dateCreated",
    text: "Last Update",
    sort: true,
    formatter: cell => <>{moment(cell).format("DD.MM.YYYY")}</>,
    headerStyle: _ => ({
      textAlign: "center",
    }),
    align: () => "center",
  },
  {
    dataField: "menu",
    text: "",
    align: () => "end",
    formatter: (cellContent, row) => (
      <>
        <Link to={`/qc-requests/${row.id}`} className="btn btn-primary ">
          Open
        </Link>
      </>
    ),
  },
]

const QCStatus = ({ status, declined }) => {
  switch (status) {
    case 1:
      return <Badge className="bg-warning py-1 px-2">open</Badge>

    case 2:
      return <Badge className="bg-warning py-1 px-2">offer provided</Badge>

    case 3:
      return <Badge className="bg-success py-1 px-2">offer accepted</Badge>

    case 4:
      return <Badge className="bg-info py-1 px-2">In Transit</Badge>

    case 5:
      return <Badge className="bg-danger py-1 px-2">In Review</Badge>

    case 6:
      return <Badge className="bg-success py-1 px-2">Report available</Badge>

    case 7:
      return declined ? (
        <Badge className="bg-danger py-1 px-2">Report declined</Badge>
      ) : (
        <Badge className="bg-success py-1 px-2">Report accepted</Badge>
      )

    case 8:
      return <Badge className="bg-info py-1 px-2">Closed</Badge>

    default:
      return <Badge className="bg-success"> {status} </Badge>
  }
}
