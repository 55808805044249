import React, { useState } from "react"
import { Row, Col, Card, Form, CardSubtitle, Alert } from "reactstrap"
import Dropzone from "react-dropzone"
import { Link, useNavigate } from "react-router-dom"
import { useLoading } from "helpers/custom_hooks"
import { displayMessage } from "helpers/misc_helpers"
import _ from "lodash"
import MessageModal from "components/Common/MessageModal"
import { UpdateQCRequest, UploadQCAttachment } from "helpers/backend_helper"
import NumberInput from "components/Common/NumberInput"

const QCOfferFileDrop = ({ entry, reload }) => {
  const setLoading = useLoading()
  const [error, setError] = useState()
  const [selectedFiles, setselectedFiles] = useState([])
  const [price, setPrice] = useState("")
  const [currency, setCurrency] = useState("EUR")
  const [successModal, setSuccessModal] = useState(false)

  const handleFileUpload = files => {
    const file = files[0]
    Object.assign(file, {
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
    })

    const reader = new FileReader()

    reader.readAsArrayBuffer(file)
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleSave = async () => {
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append("file", selectedFiles[0])
      formData.append("id", entry?.id)
      const result = await UploadQCAttachment(formData)
      entry.offerFileURL = result?.url
      entry.dateOfferUploaded = new Date()
      entry.price = price
      entry.currency = currency
      entry.statusIndex++

      await UpdateQCRequest(entry)

      setSuccessModal(true)
    } catch (error) {
      error?.response?.status == 400
        ? displayMessage(error.response.data, setError)
        : displayMessage("An unexpected error happened!", setError)
      console.log(error)
    }
    setLoading(false)
  }

  return (
    <>
      <div className="px-3">
        {error && <Alert color="danger">{error}</Alert>}
        <Row>
          <h6 className="card-title">Offer Upload</h6>
          <CardSubtitle className="mb-3">
            Please upload your offer for the quality check. (Only PDF files are
            allowed)
          </CardSubtitle>
        </Row>

        <Form>
          <Dropzone
            onDrop={acceptedFiles => {
              handleFileUpload(acceptedFiles)
            }}
            accept={{
              "application/pdf": [],
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone">
                <div className="dz-message needsclick mt-2" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h4>Drop files here or click to upload.</h4>
                </div>
              </div>
            )}
          </Dropzone>
          <div className="dropzone-previews mt-3" id="file-previews">
            {selectedFiles.map((f, i) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <i className="mdi mdi-file-pdf font-size-24 font-primary text-success" />
                      </Col>
                      <Col className="col-auto me-4">
                        <Link to="#" className="text-muted font-weight-bold">
                          {f.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{f.formattedSize}</strong>
                        </p>
                      </Col>
                      <Col className="ms-auto text-end">
                        <i
                          className="bx bx-x cursor-pointer font-size-20 text-danger"
                          onClick={() => setselectedFiles([])}
                        />
                      </Col>
                    </Row>
                  </div>
                </Card>
              )
            })}
          </div>

          <Row>
            <Col style={{ maxWidth: "200px", minWidth: "150px" }}>
              <label>Total costs:</label>
              <NumberInput initial="" onChange={setPrice} />
            </Col>
            <Col>
              <select
                type="text"
                style={{ maxWidth: "75px" }}
                onChange={e => setCurrency(e.target.value)}
                className="form-select chat-input ps-3 pe-3 mt-4"
                placeholder="Enter price..."
                value={currency ?? ""}
              >
                <option value="EUR">EUR</option>
                <option value="USD">USD</option>
                <option value="GBP">GBP</option>
                <option value="YEN">YEN</option>
              </select>
            </Col>
          </Row>

          <button
            type="button"
            onClick={handleSave}
            disabled={selectedFiles.length == 0 || !price}
            className="btn btn-primary mt-3"
          >
            Save Offer
          </button>
        </Form>
      </div>
      <MessageModal
        show={successModal}
        displayMessage={"Upload successful!"}
        onCloseClick={reload}
        color="success"
        icon="bx bx-check"
      />
    </>
  )
}

export default QCOfferFileDrop
