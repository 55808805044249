import React from "react"
import { useLoading } from "helpers/custom_hooks"
import { UpdateQCRequest } from "helpers/backend_helper"
import { displayMessage } from "helpers/misc_helpers"
import moment from "moment"

const QCAcceptOfferListItem = ({ entry, myUser, statusIndex, reload }) => {
  const setLoading = useLoading()

  const handleAcceptOffer = async () => {
    setLoading(true)
    try {
      if (isUserRequestor()) entry.dateOfferAcceptedRequestor = new Date()
      else entry.dateOfferAcceptedSeller = new Date()

      // if requestor and seller are the same company, set both dates
      if (entry?.requestorCompanyID == entry?.sellerCompanyID)
        entry.dateOfferAcceptedSeller = new Date()

      if (entry?.dateOfferAcceptedRequestor && entry?.dateOfferAcceptedSeller)
        entry.statusIndex++

      await UpdateQCRequest(entry)
      reload()
    } catch (error) {
      error?.response?.status == 400
        ? displayMessage(error.response.data, setError)
        : displayMessage("An unexpected error happened!", setError)
      console.log(error)
    }
  }

  const getAcceptedDate = () => {
    const parsedDate1 = new Date(entry.dateOfferAcceptedRequestor)
    const parsedDate2 = new Date(entry.dateOfferAcceptedSeller)

    // Determine the more recent date
    const biggerDate = parsedDate1 > parsedDate2 ? parsedDate1 : parsedDate2

    return moment(biggerDate).format("DD.MM.YYYY")
  }

  const hasSellerAccepted = () => entry?.dateOfferAcceptedSeller

  const hasRequestorAccepted = () => entry?.dateOfferAcceptedRequestor

  const isUserRequestor = () => myUser?.company?.id == entry?.requestorCompanyID

  const isUserSeller = () => myUser?.company?.id == entry?.sellerCompanyID

  return (
    <>
      {entry?.dateOfferAcceptedRequestor && entry?.dateOfferAcceptedSeller ? (
        <li
          className={
            "event-list " + (entry?.statusIndex == statusIndex ? "active" : "")
          }
        >
          <div
            className={
              "event-timeline-dot " +
              (entry?.statusIndex == statusIndex ? "bx-fade-right" : "")
            }
          >
            <i className="bx bx-right-arrow-circle font-size-18"></i>
          </div>
          <div className="d-flex">
            <div className="flex-shrink-0 me-3">
              <h5 className="font-size-14">
                {getAcceptedDate()}
                <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
              </h5>
            </div>
            <div className="flex-grow-1">
              <div>Offer accepted by both parties</div>
            </div>
          </div>
        </li>
      ) : (
        entry?.statusIndex == statusIndex - 1 &&
        ((isUserSeller() && !hasSellerAccepted()) ||
          (isUserRequestor() && !hasRequestorAccepted())) && (
          <>
            <p className="ms-3">
              <strong>
                You have received the quotation for the chosen QC inspection.
                Please confirm to initialize the further process.
              </strong>
            </p>
            <p className="ms-3">
              <strong className="text-danger">Important:</strong>
              <br />
              The costs for the inspection, transport, handling, etc. are{" "}
              <u>covered by the requestor.</u> Unless the results of the
              inspection report are unveiling insufficient quality. In this
              case, the quality inspection costs are to be covered by the
              seller.
            </p>
            <button
              type="button"
              onClick={handleAcceptOffer}
              className="btn btn-primary ms-3"
            >
              Accept Offer
            </button>
          </>
        )
      )}
    </>
  )
}

export default QCAcceptOfferListItem
