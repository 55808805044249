import React, { useState } from "react"
import { useLoading } from "helpers/custom_hooks"
import { Form, Formik } from "formik"
import FormikInputField from "helpers/FormikInputField"
import { UpdateQCRequest } from "helpers/backend_helper"
import { displayMessage } from "helpers/misc_helpers"
import { Alert, Col, Row } from "reactstrap"
import moment from "moment"

const QCOrderInformationInput = ({ entry, myUser, statusIndex, reload }) => {
  const setLoading = useLoading()
  const fields = [
    {
      fieldName: "freightForwarder",
      label: "Freight Forwarder Information",
      component: FormikInputField,
    },
    {
      fieldName: "freightAccountNumber",
      label: "Freight Forwarder Account Number",
      component: FormikInputField,
    },
    {
      fieldName: "internalOrderNumber",
      label: "Internal Order Number (optional)",
      component: FormikInputField,
    },
  ]

  const [error, setError] = useState()
  const initialValues = {
    freightForwarder: "",
    freightAccountNumber: "",
    internalOrderNumber: "",
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true)
    try {
      console.log(values)
      entry.freightForwarder = values.freightForwarder
      entry.freightAccountNumber = values.freightAccountNumber
      entry.internalOrderNumber = values.internalOrderNumber
      entry.dateFreightInfo = new Date()
      entry.statusIndex++

      console.log(entry)

      await UpdateQCRequest(entry)
      reload()
    } catch (error) {
      error?.response?.status == 400
        ? displayMessage(error.response.data, setError)
        : displayMessage("An unexpected error happened!", setError)
      console.log(error)
    }
  }

  const isUserRequestor = () => myUser?.company?.id == entry?.requestorCompanyID

  return (
    <>
      {entry?.statusIndex >= statusIndex ? (
        <li
          className={
            "event-list " + (entry?.statusIndex == statusIndex ? "active" : "")
          }
        >
          <div
            className={
              "event-timeline-dot " +
              (entry?.statusIndex == statusIndex ? "bx-fade-right" : "")
            }
          >
            <i className="bx bx-right-arrow-circle font-size-18"></i>
          </div>
          <div className="d-flex">
            <div className="flex-shrink-0 me-3">
              <h5 className="font-size-14">
                {moment(entry.dateFreightInfo).format("DD.MM.YYYY")}{" "}
                <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
              </h5>
            </div>
            <div className="flex-grow-1">
              <div>Order Data provided</div>
            </div>
          </div>
          <div className="mt-2">
            <p className="mb-1">
              <b>Freight Forwarder:</b> {entry?.freightForwarder ?? "-"}
            </p>
            <p className="mb-1" style={{ cursor: "pointer" }}>
              <b>Account Number:</b> {entry?.freightAccountNumber ?? "-"}{" "}
              <i className="bx bx-copy text-primary ms-1" />
            </p>
            <p className="mb-1">
              <b>Internal Order Number:</b> {entry?.internalOrderNumber ?? "-"}
            </p>
          </div>
        </li>
      ) : (
        entry?.statusIndex == statusIndex - 1 &&
        isUserRequestor() && (
          <Row>
            <Col className="px-5">
              {error && <Alert color="danger">{error}</Alert>}

              <p>
                Your QC request is accepted. Please fill in the freight
                forwarder information, your account number and eventually your
                purchase order number for the QC lab to start the process.
              </p>
              <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({ errors, touched }) => (
                  <Form>
                    <Row>
                      {fields.map((field, key) => (
                        <Col lg="6" key={key}>
                          <field.component
                            fieldName={field.fieldName}
                            label={field.label}
                            errors={errors}
                            touched={touched}
                          />
                        </Col>
                      ))}
                    </Row>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        )
      )}
    </>
  )
}

export default QCOrderInformationInput
