import moment from "moment"
import React from "react"

const QCDeclinedInfo = ({ entry, statusIndex, trackingCount }) => {
  return (
    <>
      {entry?.dateDeclined && (
        <li
          className={
            "event-list " + (entry?.statusIndex == statusIndex ? "active" : "")
          }
        >
          <div
            className={
              "event-timeline-dot " +
              (entry?.statusIndex == statusIndex ? "bx-fade-right" : "")
            }
          >
            <i className="bx bx-right-arrow-circle font-size-18"></i>
          </div>
          <div className="d-flex">
            <div className="flex-shrink-0 me-3">
              <h5 className="font-size-14">
                {moment(entry.dateDeclined).format("DD.MM.YYYY")}{" "}
                <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
              </h5>
            </div>
            <div className="flex-grow-1">
              <div>Report Declined</div>
            </div>
          </div>
          <div className="mt-2">
            <p className="mb-0" style={{ cursor: "pointer" }}>
              The inspection report is not accepted. According to the agreed
              terms and conditions, the costs for the inspection are at Sellers
              expenses. You will receive a separate invoice from the QC lab. In
              case of payment in advance for the goods, please follow the
              following attached payment instructions.
              <br />
              <br />
              For further discussions regarding the shipment of the goods,
              please use the chat on the right side.
            </p>
          </div>
        </li>
      )}
    </>
  )
}

export default QCDeclinedInfo
