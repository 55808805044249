import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"
import classNames from "classnames"
import QCTypeTab from "./QCTypeTab"
import QCLaborTab from "./QCLaborTab"
import QCConfirmationTab from "./QCConfirmationTab"
import QCRequestDataTab from "./QCRequestDataTab"
import { useQuery } from "helpers/custom_hooks"
import { GetRequestByID } from "helpers/backend_helper"

const QCWizard = () => {
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [request, setRequest] = useState({})
  const navLinks = [
    "QC Type Selection",
    "QC Labor Selection",
    "Summary",
    "Confirm Detail",
  ]
  const query = useQuery()
  const requestID = query.get("requestID")

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  useEffect(() => {
    if (requestID) loadRequestByID()
  }, [requestID])

  const loadRequestByID = async _ => {
    const result = await GetRequestByID(requestID)

    if (result) {
      setRequest({
        ...request,
        internalPart: result?.partIPN,
        manufacturer: result?.partManufacturer,
        partNumber: result?.partNumber,
        partRequestID: result?.id,
        quantity: result?.quantity,
        sellerCompanyID: result?.sellerCompanyID,
        requestorCompanyID: result?.buyerCompanyID,
      })
    }
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col>
              <Card className="overflow-hidden">
                <CardBody className="">
                  <h4 className="card-title mb-4">Registration</h4>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        {navLinks.map((item, index) => (
                          <NavItem
                            key={index}
                            className={classNames({
                              current: activeTab === index + 1,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                current: activeTab === index + 1,
                              })}
                              onClick={() => {
                                setactiveTab(index + 1)
                              }}
                              disabled={
                                !(passedSteps || []).includes(index + 1)
                              }
                            >
                              <span className="number">{index + 1}.</span>{" "}
                              {item}
                            </NavLink>
                          </NavItem>
                        ))}
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <QCTypeTab
                          nextTab={_ => toggleTab(activeTab + 1)}
                          request={request}
                          setRequest={setRequest}
                        />

                        <QCLaborTab
                          nextTab={_ => toggleTab(activeTab + 1)}
                          request={request}
                          setRequest={setRequest}
                          currentTab={activeTab}
                        />

                        {activeTab == 3 && (
                          <QCRequestDataTab
                            request={request}
                            nextTab={_ => toggleTab(activeTab + 1)}
                          />
                        )}

                        <QCConfirmationTab />
                      </TabContent>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default QCWizard
