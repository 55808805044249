import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"
import { columns } from "./columns"
const { SearchBar } = Search

const QCRequestsList = ({ entries, loadData }) => {
  const node = React.createRef()

  const pageOptions = {
    sizePerPage: 10,
    totalSize: entries?.length,
    custom: true,
  }

  const defaultSorted = [
    {
      dataField: "dateCreated",
      order: "desc",
    },
  ]

  return (
    <Card>
      <CardBody>
        <PaginationProvider
          pagination={paginationFactory(pageOptions || [])}
          keyField="id"
          columns={columns || []}
          data={entries || []}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              data={entries || []}
              columns={columns || []}
              remote={{ filter: true }}
              bootstrap4
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row>
                    <Col className="text-start">
                      <div className="w-25 custom-searchbar">
                        <SearchBar
                          {...toolkitProps.searchProps}
                          className="w-100"
                        />
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Link
                        to="/qc-request-wizard"
                        className="btn btn-primary mb-2 ms-4"
                      >
                        Create QC-Order
                      </Link>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <BootstrapTable
                      {...toolkitProps.baseProps}
                      {...paginationTableProps}
                      bordered={false}
                      hover={true}
                      classes={"table align-middle table-nowrap table-check"}
                      headerWrapperClasses={"table-light"}
                      defaultSorted={defaultSorted}
                      ref={node}
                    />
                    {entries?.length == 0 && (
                      <p className="text-center">
                        There are currently no requests!
                      </p>
                    )}
                  </div>
                  <div className="pagination pagination-rounded justify-content-end mb-2">
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  )
}

export default QCRequestsList
