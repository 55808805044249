import React from "react"
import QCOfferFileDrop from "./QCOfferFileDrop"
import moment from "moment"

const QCOfferListItem = ({ entry, myUser, statusIndex, reload }) => {
  return (
    <>
      {entry?.offerFileURL ? (
        <li
          className={
            "event-list " + (entry?.statusIndex == statusIndex ? "active" : "")
          }
        >
          <a
            href={entry?.offerFileURL}
            target="_blank"
            rel="noreferrer"
            className="text-dark"
          >
            <div
              className={
                "event-timeline-dot " +
                (entry?.statusIndex == statusIndex ? "bx-fade-right" : "")
              }
            >
              <i className="bx bx-right-arrow-circle font-size-18"></i>
            </div>
            <div className="d-flex">
              <div className="flex-shrink-0 me-3">
                <h5 className="font-size-14">
                  {moment(entry?.dateOfferUploaded).format("DD.MM.YYYY")}{" "}
                  <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                </h5>
              </div>
              <div className="flex-grow-1">
                <div>Offer provided by QC Labor</div>
              </div>
            </div>
            <div className="d-flex align-items-center mt-3">
              <div style={{ width: "45px" }}>
                <div className="avatar-sm">
                  <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-24">
                    <i className="bx bxs-file-doc" />
                  </span>
                </div>
              </div>
              <div className="w-25 ms-4">
                <h5 className="font-size-14 mb-1">QC Offer</h5>
                <small>Klick To Download</small>
              </div>
              <div>
                <div className="text-center">
                  <i className="bx bx-download h3 m-0" />
                </div>
              </div>
            </div>
          </a>
          <div className="mt-4">
            <b>Seller and Requestor Status:</b>
          </div>
          <div className="d-flex mt-2">
            <div>Requestor Confirmation: </div>
            <div className="ms-2">
              {entry?.dateOfferAcceptedSeller ? (
                <span className="badge bg-success px-2 py-1">Confirmed</span>
              ) : (
                <span className="badge bg-warning px-2 py-1">Pending</span>
              )}
            </div>
          </div>
          <div className="d-flex mt-2">
            <div>Seller Confirmation: </div>
            <div className="ms-2">
              {entry?.dateOfferAcceptedRequestor ? (
                <span className="badge bg-success px-2 py-1">Confirmed</span>
              ) : (
                <span className="badge bg-warning px-2 py-1">Pending</span>
              )}
            </div>
          </div>
        </li>
      ) : (
        entry?.statusIndex == statusIndex - 1 &&
        entry?.qcLaborID == myUser?.company?.id && (
          <QCOfferFileDrop entry={entry} reload={reload} />
        )
      )}
    </>
  )
}

export default QCOfferListItem
