import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import Overview from "./Overview"
import { useLoading } from "helpers/custom_hooks"
import { GetQCRequestByID } from "helpers/backend_helper"
import { useNavigate, useParams } from "react-router-dom"
import logolight from "assets/images/logo-light.png"

const QCPrintVersion = () => {
  const [entry, setEntry] = useState()
  const navigate = useNavigate()
  const setLoading = useLoading()
  let { id } = useParams()

  const loadData = async () => {
    setLoading(true)
    try {
      const result = await GetQCRequestByID(id)
      setEntry(result)
    } catch (error) {
      console.log(error)
      if (error.response.status === 404) navigate("/404")
      else alert("An unexpected error happened!")
    }
    setLoading(false)
    setTimeout(() => {
      window.print()
    }, 500)
  }

  useEffect(() => {
    loadData()
  }, [id])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="email-template">
            <Row>
              <Col md="4">
                <Card className="mb-0">
                  <CardBody className="pb-0 mb-0">
                    <img
                      src={logolight}
                      alt=""
                      width="200"
                      style={{
                        maxWidth: "200px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "block",
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="">
              <Col md="4">
                <Overview entry={entry} />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default QCPrintVersion
