import React from "react"
import { useLoading } from "helpers/custom_hooks"
import { UpdateQCRequest } from "helpers/backend_helper"
import { displayMessage } from "helpers/misc_helpers"
import moment from "moment"

const QCAcceptReportListItem = ({ entry, myUser, statusIndex, reload }) => {
  const setLoading = useLoading()

  const handleDecistion = async accepted => {
    setLoading(true)
    try {
      if (accepted) entry.dateAccepted = new Date()
      else entry.dateDeclined = new Date()
      entry.statusIndex++

      await UpdateQCRequest(entry)
      reload()
    } catch (error) {
      error?.response?.status == 400
        ? displayMessage(error.response.data, setError)
        : displayMessage("An unexpected error happened!", setError)
      console.log(error)
    }
  }

  const hasRequestorAccepted = () => entry?.dateAccepted

  const isUserRequestor = () => myUser?.company?.id == entry?.requestorCompanyID

  return (
    <>
      {entry?.dateAccepted ? (
        <li
          className={
            "event-list " + (entry?.statusIndex == statusIndex ? "active" : "")
          }
        >
          <div
            className={
              "event-timeline-dot " +
              (entry?.statusIndex == statusIndex ? "bx-fade-right" : "")
            }
          >
            <i className="bx bx-right-arrow-circle font-size-18"></i>
          </div>
          <div className="d-flex">
            <div className="flex-shrink-0 me-3">
              <h5 className="font-size-14">
                {moment(entry?.dateAccepted).format("DD.MM.YYYY")}
                <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
              </h5>
            </div>
            <div className="flex-grow-1">
              <div>Report accepted by requestor</div>
            </div>
          </div>
        </li>
      ) : (
        entry?.statusIndex == statusIndex - 1 &&
        isUserRequestor() &&
        !hasRequestorAccepted() && (
          <>
            <p className="ms-3">
              Attached you can find the QC report. Please confirm acceptance of
              the report to proceed with the shipment. Or push reject to return
              the goods to the seller.
            </p>
            <button
              type="button"
              onClick={_ => handleDecistion(true)}
              className="btn btn-primary ms-3"
            >
              Accept Report
            </button>
            <button
              type="button"
              onClick={_ => handleDecistion(false)}
              className="btn btn-danger ms-3"
            >
              Decline Report
            </button>
          </>
        )
      )}
    </>
  )
}

export default QCAcceptReportListItem
