import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import { useLoading } from "helpers/custom_hooks"
import { GetAllQCRequests } from "helpers/backend_helper"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import QCRequestsList from "./QCRequestsList"

const QCRequests = props => {
  const setLoading = useLoading()
  const [entries, setEntries] = useState([])

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async _ => {
    setLoading(true)
    const result = await GetAllQCRequests()
    setEntries(result)
    setLoading(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Requests" breadcrumbItem="QC" />

          <QCRequestsList entries={entries} loadData={loadData} />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default QCRequests
